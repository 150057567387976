@import '../abstracts/variables/color.scss';

.custom-tabset {
  .tab-container {
    .nav-tabs {
      .nav-link {
        font-weight: 500;
        color: $secondary;
        &.active {
          color: $primary;
        }
      }
    }

    .tab-content {
      padding-top: 1.5rem;
    }
  }
}
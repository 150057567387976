@import '../abstracts/variables';

$main-acc-w: 75px;
$dropdownBoxShadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
$border-selected-item-color: #D7E4F2;
$border-selected-item: 1px solid $border-selected-item-color;

$filter-item-bg: #edf5fe;
$filter-item-clear: #d1e8ff;

.user-account-dropdown {
  app-avatar.my-acc {
    .app-avatar-wrapper {
      background-color: white !important;
    }
  }
}

.user-account-dropdown-menu {
  margin-top: 1rem;

  * {
    white-space: nowrap;
  }

  .btn-outline-secondary {
    width: 100%;
    border-color: $light-blue;
    font-weight: 600;
  }

  .main-acc {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid $border-color;

    app-avatar {
      .app-avatar-wrapper {
        width: $main-acc-w;
        min-width: $main-acc-w;
        height: $main-acc-w;
        min-height: $main-acc-w;
      }
    }
  }

  .acc-list {
    border-bottom: 1px solid $border-color;
    padding: 1rem 0;
    max-height: 50vh;
    overflow-y: auto;

    .acc-wrapper {
      display: flex;
      padding: 0.5rem 2rem;
      cursor: pointer;

      &:hover {
        background-color: $option-hover-color;
      }

      .right-block {
        padding-left: 1rem;
      }

      &.add_acc {
        align-items: center;
      }
    }
  }

  .footer {
    padding: 1rem 2rem;
  }
}

.my-acc-dropdown-menu {
  box-shadow: $dropdownBoxShadow;
  min-width: 275px;

  * {
    white-space: nowrap;
  }

  .icon-block {
    width: 50px;
    display: flex;
    justify-content: center;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    padding-left: calc(1.5rem - 9px);

    >img {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }

  .header {
    padding: 0.25rem 1.5rem;
    padding-left: calc(1.5rem - 9px);
    display: flex;

    app-avatar {
      margin-top: 5px;
    }
  }
}

@import "../abstracts/variables";

.bs-datepicker {
  .bs-datepicker-container {
    .bs-datepicker-body table td:not(.week) span:not(.disabled):not(.is-other-month):not(.selected) {
      color: black;
    }

    .bs-datepicker-body table td.week span {
      color: $color-week;
    }

    .bs-datepicker-body table th[aria-label="weekday"] {
      color: $color-weekday;
      font-weight: 600;

      &:nth-child(2), &:last-child {
        color: red;
      }
    }

    .bs-datepicker-head {
      background-color: $primary;
    }

    .bs-datepicker-body table td span.selected,
    .bs-datepicker-body table td.selected span,
    .bs-datepicker-body table td span[class*="select-"]:after,
    .bs-datepicker-body table td[class*="select-"] span:after {
      background-color: $primary;
    }
  }
}

@import "../abstracts/variables";

.task-detail-wrapper {
  background-color: white !important;
  height: calc(100vh - #{$navbarHeight});
  position: relative;

  .task-detail-content {
    height: 100%;
    overflow-y: auto;
  }

  .label,
  .form-group label {
    color: $secondary;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    position: sticky;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 9;
    margin-bottom: 2px;
  }

  .task-key-wrapper {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    height: $inputHeight;

    .task-icon {
      width: 1.15rem;
      height: 1.15rem;
      margin-right: 0.25rem;
    }

    .task-key {
      color: $secondary;
    }
    // .link-copy{
    //   cursor: pointer;
    // }
    // .icon-copy-link {
    //   line-height: 16px;
    //   position: relative;
    //   top: 3px;
    // }
    // .text-copy-link {
    //   line-height: 16px;
    //   font-size: 0.8rem;
    //   font-weight: normal;
    // }
  }

  .icon-button-list {
    margin-bottom: 1rem;
  }

  .left-body {
    .content-editable {
      padding-left: 0;
      padding-right: 0;
    }

    .task-name {
      font-size: 1.25rem;
      font-weight: 600;
      font-weight: 400px;
    }

    .task-platform {
      font-size: 1.25rem;
    }
  }

  .task-status {
    outline: unset !important;
    margin-bottom: 1rem;

    .ng-select-container {
      background-color: var(--task-status-color) !important;
      border-color: var(--task-status-color) !important;
      cursor: pointer;
      box-shadow: unset !important;

      .ng-value-container {
        padding-right: 0.75rem;
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }

    .ng-dropdown-panel {
      min-width: 150px;
      width: max-content;
    }

    &.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      box-shadow: unset !important;
    }
  }

  .more-action {
    margin-bottom: 1rem;
    float: right;
    .dropdown-item {
      display: flex;
      align-items: center;
      .icon {
        color: $secondary;
        font-size: 1.25rem;
        margin-right: 0.5rem;
      }
    }
  }

  .task-time {
    color: $light-gray;
    font-size: 0.9rem;
  }
}

.ui-direction-row {
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  .left-content,
  .right-content {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 1.5rem;
  }

  .left-content {
    border-right: 1px solid $border-color;
  }

  .right-content {
    .task-detail-form {
      margin: 1rem 0;
      // padding: 0.75rem;
      // border: 1px solid $border-color;
    }
  }

  .header-row {
    padding-top: 1.5rem;
  }
}

.ui-direction-column {
  .task-detail-content {
    height: unset;
    overflow-y: unset;
    .task-form {
      // .body {
      //   padding: 1rem;
      //   border: 1px solid $border-color;
      // }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: calc(3px + 0.35rem);

        .label {
          font-size: 0.9rem;
          font-weight: 600;
        }

        .icon {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.custom-modal-content {
  max-height: 800px;
}

.task-detail-form-wrapper {
  padding: 1rem;
  border: 1px solid $border-color;

  .form-group {
    display: flex;
    align-items: center;

    label {
      color: $secondary;
      margin-bottom: 0;
      width: 100px;
      min-width: 100px;

      &.platform-label {
        line-height: 32px;
        align-self: flex-start;
      }
    }

    .custom-option {
      .btn-clear {
        cursor: pointer;
        font-size: 1rem;
        color: #999;
      }

      .epic-label {
        color: white;
        font-weight: normal;
      }

      .label-badge {
        color: white;
        padding: 0 0.5rem;
        background-color: $secondary;
        border-radius: 0.25rem;
      }
    }

    .ng-option {
      .btn-clear {
        display: none;
      }
    }

    .ng-placeholder {
      padding-left: 0 !important;
    }

    .control {
      flex: 1;
      min-width: 0;

      &.estimate-control {
        display: flex;
        flex-direction: column;

        .estimate-item {
          display: flex;
          align-items: center;

          &:not(:first-child) {
            margin-top: 0.5rem;
          }

          .platform {
            width: 100px;
            min-width: 100px;
            display: flex;
          }

          app-input {
            flex: 1;
          }
        }
      }

      .checkbox-control {
        margin-left: 0.75rem;
      }

      .text-only {
        border-color: white !important;
      }

      .form-control,
      .ng-select .ng-select-container {
        border-color: white;

        .ng-clear-wrapper,
        .ng-arrow-wrapper,
        .btn-clear {
          display: none;
        }
      }

      .form-control:hover,
      .ng-select:hover .ng-select-container {
        border-color: $form-border-color;
      }

      .form-control:focus,
      .ng-select.ng-select-opened .ng-select-container {
        border-color: $primary;

        .ng-clear-wrapper,
        .ng-arrow-wrapper,
        .btn-clear {
          display: inline-block;
        }
      }
    }
  }

  .ng-select.ng-select-multiple.label-control .ng-value-container {
    padding-top: 0px;
  }
}

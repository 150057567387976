@charset "UTF-8";

@import "./abstracts/variables", "./abstracts/mixins", "./abstracts/functions";

@import "./vendors/bootstrap", "./vendors/data-table", "./vendors/ng-select",
  "./vendors/cdk-drap-drop", "./vendors/utils", "./vendors/ant-tooltip";

@import "./base/base", "./base/fonts", "./base/typography";

@import "./layout/header", "./layout/sidebar", "./layout/auth", "./layout/landing";

@import "./components/button", "./components/navtab", "./components/datepicker", "./components/input", "./components/dropdown",
  "./components/tooltip", "./components/badge", "./components/modal", "./components/okr", "./components/overlay", "./components/form",
  "./components/task", './components/gantt', './components/ngselect', "./components/quill", "./components/avatar";

@import "./themes/default";

html {
  // base
  --x-white: #ffffff;
  --x-black: #000000;
  --x-orange: #ff7656;
  --x--gray: #f5f6f8;

  // app
  --x-primary: #0053b3;
  --x-secondary: #42526e;
  --x-border: #dde8f1;
  --x-border-secondary: #cddbe3;
  --x-border-dropdown: #e6edf1;
  --x-error: #ec4d57;
  --x-border-table: #ddd;
  --x-option-hover: #f5faff;
  --x-blue: #4c9aff;
  --x-text-color: #172b4d;
  --x-light-color: #eef1f6;
  --x-light-gray-color: #d5dce9;

  // business
  --x-task-background: #dce5ff;
  --x-task: #537dff;
  --x-story-background: #e4f2e4;
  --x-story: #77be79;
  --x-bug-background: #fbdbdd;
  --x-bug: #ec4d57;
  --x-border-selected-item: #d7e4f2;
  --x-filter-item-clear: #d1e8ff;
}


.cp { cursor: pointer !important; }
.cd { cursor: default; pointer-events: none; }

.fl { float: left; }
.fr { float: right; }
.fn { float: none; }
.cb { clear: both !important; }

.flex { display: flex; }
.jc_sb { justify-content: space-between; };
.ai_c { align-items: center; }

.ta_l { text-align: left; }
.ta_c { text-align: center; }
.ta_r { text-align: right; }
.va_t { vertical-align: top; }

.oh { overflow: hidden !important; }
.vh { visibility: hidden; }
.dn { display: none !important; }
.di { display: inline !important; }
.pr { position: relative !important; }
.pa { position: absolute !important; }
.pf { position: fixed !important; }

.t10 { top: 10px !important; }
.r0 { right: 0 !important; }
.b0 { bottom: 0 !important; }
.l0 { left: 0 !important; }
.l70 { left: 70px !important; }

.m0 { margin: 0 !important; }
.mt0 { margin-top: 0px !important; }
.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt40 { margin-top: 40px !important; }
.mt50 { margin-top: 50px !important; }
.mt100 { margin-top: 100px !important; }
.mr0 { margin-right: 0px !important; }
.mr5 { margin-right: 5px !important; }
.mr10 { margin-right: 10px !important; }
.mr15 { margin-right: 15px !important; }
.mr20 { margin-right: 20px !important; }
.mb0 { margin-bottom: 0px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb50 { margin-bottom: 50px !important; }
.ml5 { margin-left: 5px !important; }
.ml0 { margin-left: 0px !important; }
.ml10 { margin-left: 10px !important; }
.ml20 { margin-left: 20px !important; }
.ml25 { margin-left: 25px !important; }
.ml30 { margin-left: 30px !important; }

.p0 { padding: 0 !important; }
.p4 { padding: 4px !important; }
.p5 { padding: 5px !important; }
.p20 { padding: 20px !important; }
.pt0 { padding-top: 0px !important; }
.pt5 { padding-top: 5px !important; }
.pt10 { padding-top: 10px !important; }
.pb0 { padding-bottom: 0 !important; }
.pl0 { padding-left: 0px !important; }
.pl10 { padding-left: 10px !important; }
.pl20 { padding-left: 20px !important; }

.wa { width: auto !important; }
.w30 { width: 30px; }
.w50 { width: 50px; }
.w15_100 { width: 15% !important; }
.w20_100 { width: 20% !important; }
.w25_100 { width: 25% !important; }
.w30_100 { width: 30% !important; }
.w40_100 { width: 40% !important; }
.w45_100 { width: 45% !important; }
.w50_100 { width: 50% !important; }
.w60_100 { width: 60% !important; }
.w70_100 { width: 70% !important; }
.w75_100 { width: 75% !important; }
.w80_100 { width: 80% !important; }
.w90_100 { width: 90% !important; }
.w100_100 { width: 100% !important; }

.ha { height: auto !important; }
.h30 { height: 30px; }

.mw100_100 { max-width: 100%; }
.mh100_100 { max-height: 100%; }

.lh30 { line-height: 30px; }
.ti5 { text-indent: 5px; }

.c_black { color: black; }
.c_purple { color: purple; }
.c_green { color: green; }
.c_orange { color: orange; }
.c_red { color: red; }

.fw-1 { font-weight: 100; }
.fw-2 { font-weight: 200; }
.fw-3 { font-weight: 300; }
.fw-4 { font-weight: 400; }
.fw-5 { font-weight: 500; }
.fw-6 { font-weight: 600; }
.fw-7 { font-weight: 700; }
.fw-8 { font-weight: 800; }
.fw-9 { font-weight: 900; }
.fw-bold { font-weight: bold; }
.f-lt { text-decoration: line-through; }
.f-italic { font-style: italic; }

.opacity-50 { opacity: 0.5; }
.opacity-75 { opacity: 0.5; }
.hidden { visibility: hidden; }
.text-16px { font-size: 16px; }
.text-18px { font-size: 18px; }
.text-20px { font-size: 20px; }
.font-semibold { font-weight: 600; }

.w-fit { width: fit-content !important; }
.w-unset { width: unset !important; }
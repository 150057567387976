@import '../abstracts/variables';
@import '../abstracts/mixins';

.btn {
  min-height: $inputHeight;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-warning, .btn-outline-warning:hover {
  color: white;
}

.btn-success {
  color: white;
}

.btn-white {
  background-color: white;
  border-color: white;

  &:hover {
    background-color: #dfe2e8;
    border-color: #d7dbe3;
  }
}

.btn-light {
  color: $secondary;
}

.btn-more-menu.dropdown-toggle, .btn-w-32 {
  font-size: 1.5rem;
  padding: 0;
  width: $inputHeight;
  &::after {
    display: none;
  }
}

.btn-flex {
  height: unset;
  min-height: unset;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.btn-link {
    text-decoration: none !important;
  }
}

.btn-icon {
  height: unset;
  min-height: unset;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  font-size: 1.5rem;
  border-radius: 0.25rem !important;

  mat-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.btn-sm {
    font-size: 1.25rem;

    mat-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &.default {
    width: $inputHeight;
    height: $inputHeight;
  }

  &.circle {
    border-radius: 50% !important;
  }
}

.btn-toogle-panel {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #6b778c;
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;

  &:hover {
    background-color: $primary;
    color: white;
  }

  &.collapsed {
    .mat-icon {
      transform: rotate(180deg);
    }
  }
}

.btn-icon-circle {
  @include btn-icon-circle(28px);
}

.btn-mobile-icon {
  background-color: #dde8f1;
  color: $secondary;
  width: 38px;
  border-radius: 5px;
}

.btn-action-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $inputHeight;
  transition: background 0s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  font-size: 22px;
  color: $black;

  &::before {
    font-family: "Material Icons";
    content: "\e5d4";
  }

  &:hover {
    background: rgba(9, 30, 66, 0.08);
  }

  &.more-horiz {
    &::before {
      content: "\e5d3" !important;
    }
  }
}

.btn-landing {
  // font-size: 1.15rem;
  // border-radius: 0.375rem;
  // min-width: 7rem;
  // font-weight: 600;

  min-height: 40px;
  font-weight: 600;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 3px;

  &.f-lg {
    font-size: 1.15rem;
  }
}

.load-all-task-btn {
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid $border-color;
  background-color: #f5f6f8;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  font-weight: 600;

  &:hover {
    background-color: $light-blue;
    transition: all 100ms ease-in 0s;
  }
}

.icon-button-list {
  display: inline-flex;
  align-items: center;

  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #42526e;
    background-color: #f5f6f8;
    width: 42px;
    height: $inputHeight;
    transition: all 100ms ease-in 0s;
    box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 10%);
    border: 0;
    border-radius: 0;

    span {
      font-size: 22px;
    }

    .mat-icon {
      width: $icon-md;
      height: $icon-md;
    }

    .rotate {
      transform: rotate(-45deg);
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover:enabled {
      background-color: $hover-color !important;
    }
  }
}

.btn-radio {
  color: #aeb4c6;
  &:not(.active) {
    .checked {
      display: none;
    }
  }
  &.active {
    color: $primary;
    .unchecked {
      display: none;
    }
  }

  &:hover {
    color: $primary;
  }
}
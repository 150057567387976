$popover-arrow-color: #1a1a1a;
$popover-bg: rgb(26, 26, 26, 0.84);

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "../abstracts/variables";

a {
  cursor: pointer;
}

img {
  max-width: 100%;
  &:focus {
    outline: unset;
  }
}

video {
  max-width: 100%;
}

.drag-indicator {
  color: $form-border-color;
}

.header-collapse-icon {
  font-size: 1.25rem;
  color: $secondary;
}

.f-500 {
  font-weight: 500;
}

// FORM-GROUP
.form-group {
  label {
    color: rgb(107, 119, 140);
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 12px;
    display: inline-flex;
    align-items: center;

    .info-icon {
      margin-left: 0.25rem;
      display: inline-flex;
    }
  }

  .checkbox-control {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: $inputHeight;
    input {
      transform: scale($checkbox-md);
      margin-right: 0.5rem;
    }
    label {
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;
    }
  }
}

input[type=checkbox] {
  transform: scale($checkbox-md);
}

.checkbox-md {
  transform: scale($checkbox-md);
}

.mat-icon {
  max-width: 100%;
  max-height: 100%;
  display: inline-flex !important;
}

.info-icon.mat-icon {
  width: 1rem;
  height: 1rem;
}

.custom-switch .custom-control-label {
  &::before,
  &::after {
    cursor: pointer;
  }
}

.form-control {
  height: $inputHeight;
  background-color: white;
  border: $form-border;
  color: $black;

  &.disabled {
    background-color: #eef3f8 !important;
  }

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary;
  }

  &::placeholder {
    color: $logan;
    opacity: 1;
  }

  &.form-control-white {
    background-color: white;
    border-color: white;

    &::placeholder {
      color: $logan;
      opacity: 1;
    }
  }

  &.form-control-gray {
    background-color: $wild-sand;
    border-color: $wild-sand;

    &::placeholder {
      color: $logan;
      opacity: 1;
    }
  }

  &:disabled,
  &[readonly] {
    background-color: #eef3f8 !important;
  }

  &.error {
    border: 1px solid #dc3545 !important;
    box-shadow: unset !important;
  }
}

.router-outlet {
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: relative;
  padding: 10px;
  overflow-x: hidden;
  background: #f5f5f5;

  .main-wrapper {
    padding: 15px;
    background-color: white;
  }

  .skeleton-wrapper {
    overflow: hidden;
    padding: 15px;
    height: 100%;
    background-color: white;
  }
}

.router-outlet-white {
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: relative;
  padding: 15px;
  overflow-x: hidden;
}

.search-input-wrapper {
  position: relative;
  input {
    height: $inputHeight;
    border-radius: 0.25rem !important;
    padding-right: 40px;

    &:focus {
      border: 1px solid $primary;
      outline: 0;
      box-shadow: 0 0 0 1px $primary;
    }
  }
  .input-group-prepend {
    height: $inputHeight;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 5;
    .input-group-text {
      background-color: transparent;
      border: 0;

      &:hover {
        color: $primary;
      }
    }
  }
}

.custom-time-picker {
  .bs-timepicker-field {
    background: #f4f4f4;
    border: 0;
    line-height: $inputHeight;
    &.is-invalid {
      padding-right: 0.55rem;
      background-image: unset;
    }
  }
}

.bs-datepicker-today {
  background: silver;
}

.bs-box {
  box-shadow: 0 10px 18px 0px rgba(62, 57, 107, 0.2);
}

.pointer {
  cursor: pointer;
}

.filter-list {
  display: flex;
  flex-direction: column;
  .filter-item {
    flex: 1 1;

    &.auto-width {
      max-width: unset;
      flex: unset;
    }

    .filter-label {
      color: rgb(107, 119, 140);
      margin-bottom: 3px;
      font-weight: 600;
      font-size: 12px;

      &.hide {
        visibility: hidden;
      }
    }

    // .search-input-wrapper {
    //   margin-left: 10px;
    // }

    .btn-action {
      margin-left: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 0.75rem;

      &::after {
        display: none;
      }

      .material-icons {
        font-weight: 600;
        margin-left: 0.25rem;
      }
    }

    &.date-input-group {
      display: flex;

      .item-group {
        flex: 1;
        &:first-child {
          margin-right: 5px;
        }
        &:last-child {
          margin-left: 5px;
        }
      }
    }

    &:not(:first-child) {
      margin-top: 10px;
    }

    &.action-item {
      text-align: right;
      .filter-label {
        visibility: hidden;
      }
      button {
        min-width: 100px;
      }
    }
  }
}

.text-ellipsis, .text-cut {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-2-line, .text-cut-2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.text-muted {
  color: $logan !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-dodgerblue {
  color: dodgerblue !important;
}

.text-gray {
  color: #737373 !important;
}

.text-logan {
  color: $logan !important;
}

.text-no-items {
  font-style: italic;
  color: $logan;
}

.text-discount {
  background: #fed839;
  padding: 0 0.25rem;
  border-radius: 5px;
  font-style: italic;
  font-weight: 600;
}

.content-editable {
  outline: unset;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  border: 0.8px solid white;
  word-wrap: break-word;
  white-space: pre-wrap;
  min-height: $inputHeight;
  &:hover {
    border-color: $form-border-color;
  }
  &:focus {
    background-color: unset;
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary;
  }
}

.checkbox-icon-list {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  .checkbox-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cddbe3;
    border-radius: 3px;
    transition: all 100ms ease-in 0s;
    padding: 2px;
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 2px;

    width: $icon-lg;
    min-width: $icon-lg;
    height: $icon-lg;
    min-height: $icon-lg;

    img {
      width: $icon-md;
      height: $icon-md;
      min-width: $icon-md;
      min-height: $icon-md;
    }

    &.selected, &:hover {
      border-color: $primary;
      box-shadow: 0 0 0 0.5px $primary;
    }
  }
}

.required::after {
  content: "*";
  color: red;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .filter-list {
    flex-direction: row;

    .filter-item {
      max-width: 20%;
      min-width: fit-content;
      
      &:not(:first-child) {
        margin-top: 0;
        margin-left: 10px;
      }

      &.date-input-group {
        flex: 2 2;
        max-width: 40%;

        display: flex;
      }
    }
  }
}

bs-dropdown-container {
  z-index: 1060 !important;
  .dropdown-item {
    color: $black;
  }
}

.btn {
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
}

@media (max-width: 767px) {
  .router-outlet {
    padding: 0;
    .main-wrapper {
      padding: 10px;
    }
  }
}

@import "../abstracts/variables";

@mixin heading($font-size: 1rem) {
  font-size: $font-size;
  font-weight: bold;
  color: #323131;
  display: flex;
  align-items: center;

  // &::before {
  //   content: "";
  //   display: inline-block;
  //   width: 9px;
  //   border-left: 2px solid $primary;
  //   border-right: 2px solid $primary;
  //   margin-right: $font-size;
  //   height: $font-size + 0.5rem;
  // }
}

@mixin auto-scroll {
  overflow-y: auto;
  position: relative;

  margin-right: -10px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    background-color: unset;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: unset;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      transition: all 2s ease-in 0s;
      background-color: #ebecf0;
      opacity: 0.5;
    }
  }

  .fixed-bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 99;
    margin-right: -3px;
  }
}

@mixin landing-page {
  position: relative;

  .text-blue {
    color: $primary;
  }

  .check-list {
    margin-top: 2rem;

    @media (max-width: 768px) {
      margin-top: 1rem;
    }

    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: url("../../assets/img/landing-pages/check-box.svg");
        transform: scale(0.85);
        display: inline-flex;
        margin-right: 0.75rem;
      }
    }
  }

  .intro-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140vh;
    background-color: #d5f7fc;
    border-bottom-left-radius: 7rem;
    border-bottom-right-radius: 7rem;
    z-index: -1;

    .img-bg {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 40%;
      max-height: calc(100% - 7rem);
    }

    @media (max-width: 768px) {
      .img-bg {
        display: block;
        max-width: 70%;
      }
    }
  }

  .intro-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    padding-top: 125px;

    @media (max-width: 768px) {
      padding-top: 370px;
      min-height: fit-content;

      .content {
        margin-bottom: 2rem;
      }
    }

    .subtitle {
      font-size: 1.15rem;
      color: $primary;
      font-weight: 600;
    }

    @media (min-width: 1610px) {
      .subtitle {
        display: none;
      }
    }

    .title {
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.3;
    }

    .content {
      margin-top: 1.65rem;
      margin-bottom: 3.85rem;
    }

    .btn-landing.try-free {
      margin-top: 3rem;
      margin-bottom: 3rem;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .section-wrapper {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;

    &.section-sign-up {
      color: white;
      background-color: $primary;

      .btn {
        font-size: 1.25rem;
        margin-top: 2rem;
      }
    }

    .section-title {
      font-size: 2rem;
      font-weight: bold;
    }

    .section-subtitle {
      font-size: 1.15rem;
      font-weight: 600;
      margin-top: 0.85rem;
    }

    .content {
      margin-top: 2rem;
    }

    @media (max-width: 768px) {
      .section-title {
        font-size: 1.25rem;
      }

      .section-subtitle {
        font-size: 1rem;
        margin-top: 0.5rem;
        font-weight: normal;
      }
    }
  }

  .hr-style1 {
    display: block;
    margin: 15px auto 10px;
    width: 116px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #0053b3;

    @media (max-width: 768px) {
      height: 2px;
    }
  }
}

@mixin center-div {
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

@mixin user-time-off-table {
  .datatable-header {
    border-bottom: 0;

    .datatable-header-cell {
      padding: 0;

      .datatable-header-cell-template-wrap {
        font-size: 14px;
        font-weight: 400;
        color: $black;
      }
    }
  }

  .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0;
  }

  .datatable-header .datatable-row-left,
  .datatable-body .datatable-row-left,
  .datatable-header .datatable-row-right,
  .datatable-body .datatable-row-right {
    background-image: unset;
  }
}

@mixin action-cell {
  .action-cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .buttons {
      display: flex;
      align-items: center;

      .btn-icon {
        font-size: 18px;
        color: #a1a8cc;
        padding: 0;
        border: 0;
        background: unset;
        outline: unset;
        width: 25px;
        height: 25px;
        border-radius: 50%;

        &:hover {
          color: $primary;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }

        .icon,
        .fa {
          font-size: 20px;
        }
      }

      .link {
        cursor: pointer;
        color: white;
        padding: 5px 15px;
        border-radius: 3px;
        max-width: 100%;
        width: 90px;
        min-width: 90px;
        text-align: center;
        border: 0;
        outline: 0;

        &.cancel {
          background: $yellow;
        }

        &.edit {
          // background: $blue;
          background: $primary;
        }

        &.approve {
          background: $success;
        }

        &.reject {
          background: $danger;
        }
      }
    }
  }
}

@mixin user-avatar($size: 30px) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  border-radius: 50%;
  background-color: #ff9a1f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  font-size: 13px;
  font-weight: 500;

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@mixin btn-icon-circle($size: 24px) {
  width: $size;
  min-width: $size;
  height: $size;
  min-height: $size;
  color: #6b778c;
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px,
    rgb(9 30 66 / 8%) 0px 2px 4px 1px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s,
    opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;

  &:hover {
    background-color: $primary;
    color: white;
  }
}

@mixin switch-date() {
  .switch-date-group {
    display: flex;
    align-items: center;

    .btn-icon-circle {
      .material-icons {
        font-weight: bold;
      }
    }

    .date-info {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      min-width: 105px;
      text-align: center;

      &.day {
        min-width: 75px;
      }
    }
  }

  .btn-today {
    border-color: #dde8f1;

    &:hover {
      color: white;
      background-color: $primary;
    }
  }
}

@mixin reportStatus {
  background-color: $report-status-gray;
  color: white;

  &.WAITING {
    background-color: white;
    border: 1px solid $report-status-green;
    color: $black;
  }

  &.COMPLETED {
    background-color: $report-status-green;
  }

  &.EARLY,
  &.LATELY,
  &.LATE,
  &.SOON,
  &.INVALID {
    background-color: $report-status-yellow;
  }

  &.MISSED {
    background-color: $report-status-red;
  }
}

@mixin scrollY {
  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 5px, black 5px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  &:hover {
    -webkit-mask-position: left top;
  }
}

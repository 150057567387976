@import "../abstracts/variables";

.badge {
  font-size: 100%;
  font-weight: 500;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0.8rem;
}

.badge-light {
  background-color: #dde8f1;
  color: $secondary;
}

.badge-success {
  color: white;
}

.badge-secondary {
  background-color: #737373;
}

.badge-label {
  color: white;
  padding: 0 0.5rem;
  background-color: #42526e;
  border-radius: 0.3rem;
}

.badge-group {
  background: linear-gradient(to bottom, #ff575e, #ff5f7f);
}

.badge-team {
  background: linear-gradient(to bottom, #57b1ff, #5f89ff);
}

.badge-group, .badge-team  {
  color: white;
  font-weight: normal;
  &.badge-main, ~.badge-suffix {
    border-radius: 0.25rem;
  }
}

.badge-platform {
  border-radius: 3px;
  background-color: #e1e4e8;
  padding-left: 5px;
  padding-right: 5px;
}

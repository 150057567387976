@import "@ng-select/ng-select/themes/default.theme.css";
@import "../abstracts/variables";

.ng-select {

  &.ng-select-single {
    .ng-select-container {
      height: $inputHeight !important;

      .ng-value-container .ng-input {
        top: 0 !important;
        height: 100%;

        >input {
          height: 100%;
        }
      }
    }
  }

  .ng-select-container {
    color: $black;
    background-color: white;
    border: $form-border;
    min-height: $inputHeight !important;
    box-shadow: unset !important;

    .ng-value-container {
      overflow: hidden;
      padding-left: 0.75rem !important;

      .ng-placeholder {
        color: $logan;
      }
    }
  }

  &.ng-select-opened {
    >.ng-select-container {
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary !important;
    }

    &.ng-select-bottom {
      >.ng-select-container {
        border-radius: 4px;
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary !important;
  }

  .ng-select-container {

    .ng-value-container {
      max-width: 100%;

      .ng-value {
        display: flex;
        align-items: center;
        overflow: hidden;

        .ng-value-label {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.bg-white {
    .ng-select-container {
      background-color: white;
    }
  }

  &.ng-select-disabled>.ng-select-container {
    background-color: #eef3f8;
  }

}

.ng-dropdown-panel {
  &.ng-select-bottom {
    margin-top: 4px;
    border-radius: 4px;
    border-color: white;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgb(9 30 66 / 13%) 0px 0px 0px 1px, rgb(9 30 66 / 13%) 0px 4px 11px;

    .ng-dropdown-panel-items {
      border-radius: 4px;
      max-height: 300px;

      .ng-option {
        color: $black;
      }
    }
  }
}

.custom-option {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // padding: 8px 1rem !important;

  // &:hover:not(:disabled) {
  //   cursor: pointer;
  //   background-color: $option-hover-color;
  // }

  // &.selected {
  //   background-color: $option-selected-color !important;
  // }

  .option-icon {
    width: $icon-md;
    height: $icon-md;

    &.avatar {
      border-radius: 50%;
    }

    &.material-icons, &.material-icons-outlined {
      width: unset;
      height: unset;
      font-size: $icon-md;
    }
  }

  .option-label {
    margin-left: 0.5rem;

    &.bold-label {
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.ng-dropdown-panel.ng-dropdown-panel-w200 {
  width: 200px !important;
}

.custom-search-dropdown-menu.dropdown-menu {
  min-width: 200px;

  // &.default {
  //   width: unset;
  // }

  // &.dropdown-menu-lg {
  //   width: 250px;
  // }

  .header {
    padding: 0.5rem 1rem;

    .title {
      color: $secondary;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    app-search-input {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .option-list {
    max-height: 200px;
    overflow-y: auto;

    &.auto-height {
      max-height: unset;
    }
  }
}

.dropdown-menu {
  >li {
    .dropdown-item {
      padding: 8px 1rem;
      outline: 0;

      &:hover,
      &:focus {
        background-color: $option-hover-color;
        color: black;
      }
    }
  }
}

.custom-dropdown-menu,
.custom-search-dropdown-menu.dropdown-menu {
  .option-list {
    .custom-option {
      padding: 8px 1rem;
      cursor: pointer;

      &:hover:not(:disabled) {
        cursor: pointer;
        background-color: $option-hover-color;
      }

      &.selected {
        background-color: $option-selected-color !important;
      }

      .badge-epic {
        color: white;
        width: 100%;
        text-align: left;
      }

      .unassigned-avatar {
        color: $light-gray;
      }
    }
  }
}

.content-editable.ng-invalid.ng-touched,
.ng-select.ng-invalid.ng-touched > .ng-select-container,
.form-control.ng-invalid.ng-touched, input.ng-invalid.ng-touched {
  border-color: red !important;
  box-shadow: 0 0 0 0.1px red !important;
}
